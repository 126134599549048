import React from 'react';
import { graphql } from 'gatsby';
import { injectIntl, Link } from 'gatsby-plugin-intl';

import {gla, contract, laborlaw, livelaw, criminal} from '../assets/icons'; 
import {SEO, Row, RowBut, Button, Heading, Layout_srv, Text ,Title, Icon, Desc, SrvText} from '../components';
import Contact from '../components/contact';
import {Heroimg, WBussines} from "../components/Images"


const SrvList = ({icon, title, alt, txt, ul, additionalClasses}) => (
        <Row>
          <Icon>
            <img src={icon} alt={alt} />
          </Icon>
          <div>  
            <Title>
             {title}
            </Title>
            <Text>
             {txt}
             <ul>{ul}</ul>
            </Text>
          </div>
        </Row>
) 

SrvList.defaultProps = {
  additionalClasses: '',
}


const Bussines = ({ intl, data }) => {
  return (
    <Layout_srv>
      <SEO title={intl.formatMessage({ id: 'bussines.title' })} />
      <div className='header_business'>
        <WBussines/>
        <div>
        <Heading>{intl.formatMessage({ id: 'bussines.title' })}</Heading>
        <SrvText className='srvdesc'>{intl.formatMessage({ id: 'bussines.desc' },{ b: chunks => <b>{chunks}</b>})}</SrvText>
        </div>
      </div>  
      <Desc className='srvlist' id="services">
        <SrvList
          icon={gla}
          alt={intl.formatMessage({ id: 'bussines.s1.title'})}
          title={intl.formatMessage({ id: 'bussines.s1.title'})}
          txt={intl.formatMessage({ id: 'bussines.s1.txt'},{ ul: chunks => <ul>{chunks}</ul>})}
          ul={intl.formatMessage({ id: 'bussines.s1.ul'},{ li: chunks => <li>{chunks}</li>})} 
          />
        <SrvList
          icon={contract}
          alt={intl.formatMessage({ id: 'bussines.s2.title'})}
          title={intl.formatMessage({ id: 'bussines.s2.title'})}
          txt={intl.formatMessage({ id: 'bussines.s2.txt'})}
          ul={intl.formatMessage({ id: 'bussines.s2.ul'},{ li: chunks => <li>{chunks}</li>})} 
        />
        <SrvList
          icon={laborlaw}
          alt={intl.formatMessage({ id: 'bussines.s3.title'})}
          title={intl.formatMessage({ id: 'bussines.s3.title'})}
          txt={intl.formatMessage({ id: 'bussines.s3.txt'})}
          ul={intl.formatMessage({ id: 'bussines.s3.ul'},{ li: chunks => <li>{chunks}</li>})} 
        />
        <SrvList
          icon={livelaw}
          alt={intl.formatMessage({ id: 'bussines.s4.title'})}
          title={intl.formatMessage({ id: 'bussines.s4.title'})}
          txt={intl.formatMessage({ id: 'bussines.s4.txt'})}
          ul={intl.formatMessage({ id: 'bussines.s4.ul'},{ li: chunks => <li>{chunks}</li>})} 
        />
        <SrvList
          icon={criminal}
          alt={intl.formatMessage({ id: 'bussines.s5.title'})}
          title={intl.formatMessage({ id: 'bussines.s5.title'})}
          txt={intl.formatMessage({ id: 'bussines.s5.txt'})}
          ul={intl.formatMessage({ id: 'bussines.s5.ul'},{ li: chunks => <li>{chunks}</li>})} 
        />
      </Desc>
      <RowBut>
        <Link to="/">
            <Button>{intl.formatMessage({ id: 'button.gohome' })}</Button>
        </Link>
        <Link to="/private-clients">
            <Button>{intl.formatMessage({ id: 'button.private' })}</Button>
        </Link>
      </RowBut>
      <Contact></Contact>
    </Layout_srv>
  );
};

export default injectIntl(Bussines);

